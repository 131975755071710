import React, { useCallback, useEffect, useState } from 'react'
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    message,
    Upload,
    Image
} from 'antd';

const Page = ({
    value,
    onChange,
    maxCount = 1,
    imageHeight = 100,
    ...nextProps
}) => {

    useEffect(() => {
    }, [value])

    return (
        <div>
            {maxCount == 1 && value != null && value != '' && (
                <Image src={value} height={imageHeight} />
            )}
            {maxCount > 1 && value != null && (
                value.map((v) => {
                    return <Image src={v} height={imageHeight} />
                })
            )}
            <Upload
                action={module.app + '/resource/v1.0/image/upload'}
                showUploadList={false}
                maxCount={maxCount}
                name='file'
                onChange={(info) => {
                    if (info.file.status === 'done') {
                        if (maxCount == 1) {
                            onChange(info.file.response.data);
                        } else {
                            const newValue = [];
                            info.fileList.map((file) => {
                                newValue.push(file.response.data);
                            })
                            onChange(newValue);
                        }
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} 上传失败`);
                    }
                }}
                {...nextProps}
            >
                <Button icon={<UploadOutlined />}>选择图片</Button>
            </Upload>
        </div>)
}

export default Page;