import axios from "axios";
import { message, Spin } from 'antd';
import ReactDOM from 'react-dom';

const parseResponse = (res, success, fail) => {
    switch (res.status) {
        case 0:
            success(res);
            break;
        case 9999:
            message.error(res.msg);
            window.location.href = "/login";
            break;
        default:
            message.error(res.msg + "[" + res.data + "]");
            fail && fail(res);
    }
}

var loadingCount = 0;
const showLoading = () => {
    if (loadingCount == 0) {
        setTimeout(function () {
            if (loadingCount > 0) {
                let dom = document.createElement("div");
                dom.setAttribute("id", "id-loading");
                document.body.appendChild(dom);
                ReactDOM.createRoot(dom).render(<Spin tip="加载中..." size="large" delay={300} />);
            }
        }, 300)
    }
    loadingCount++;
}
const hideLoading = () => {
    loadingCount--;
    if (loadingCount == 0 && document.getElementById("id-loading") != null) {
        document.body.removeChild(document.getElementById("id-loading"));
    }
}
export function post(url, param = {}, config = {}) {
    return new Promise((success, fail, reject) => {
        if (config.loading) {
            showLoading();
        }
        axios.post(url, param).then((res) => {
            if (config.loading) {
                hideLoading();
            }
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }
        }, (err) => {
            if (config.loading) {
                hideLoading();
            }
            message.error(err.message + "  [" + err.config.url + "]");
            fail && fail(err);
        })
    })
}

export function get(url, param = {}, config = {}) {
    return new Promise((success, fail, reject) => {
        axios.get(url, param).then((res) => {
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }

        }, (err) => {
            reject && reject(err);
        })
    })
}