import React, { useState, useEffect } from 'react';
import { LoginContext } from 'util/context';

import { addRoute } from 'component/DynamicRotes';
import { Layout } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import AdminHead from './admin-head';
import AdminBody from './admin-body';



import './admin.css'

import './public/index'
import './my/index';

import './ac/index';
import './app/index';
import './cloud/index'
import './dev/index'
import './crm/index'

import './paifu/index'
import './auth/password-reset'
import './ec/index'

function Page() {
    const [loginUser, setLoginUser] = useState({});

    useEffect(() => {
        post(module.auth + '/auth/v1.0/login/info', {})
            .then(res => {
                setLoginUser(res.data);
            })
    }, []);

    return (
        <Layout className="xcloud-wrapper">
            <LoginContext.Provider value={loginUser}>
                <AdminHead></AdminHead>
                <AdminBody></AdminBody>
            </LoginContext.Provider>
        </Layout >
    );
}
addRoute("*", Page);
export default Page;
