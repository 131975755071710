import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Upload,
  Select,
  Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from "component/DictSelect";
import ImageUpload from "component/ImageUpload";
import RichTextEditor from 'component/RichTextEditor'

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
  param,
  setParam,
  reload
}) => {
  const [step, setStep] = useState(1);
  const [pageForm] = Form.useForm();

  useEffect(() => {
    if (param.visible) {
      if (param.templateId != '') {
        post(module.cloud + '/template/v1.0/get', { id: param.templateId })
          .then(res => {
            pageForm.setFieldsValue({
              ...res.data
            });
          })
      } else {
        pageForm.resetFields();
      }
    }
  }, [param])

  return (<Drawer title="模块管理"
    placement="right"
    width={660}
    maskClosable={false}
    onClose={() => {
      setParam({ ...param, visible: false })
    }}
    visible={param.visible}
    extra={
      <Space>
        <Button onClick={() => {
          setParam({ ...param, visible: false })
        }}>取消</Button>
        <Button type="primary" onClick={() => {
          const param = pageForm.getFieldsValue();
          const url = param.id ? module.cloud + '/template/v1.0/update'
            : module.cloud + '/template/v1.0/create';
          post(url, param)
            .then(res => {
              reload();
              setParam({ ...param, visible: false })
              message.info("保存成功");
            })
        }}>
          确认
        </Button>
      </Space>
    }
  >
    <Form form={pageForm} layout="horizontal" hideRequiredMark
      labelCol={{
        style: { width: 80 }
      }}
      initialValues={{
        attrs: []
      }}
    >
      <Form.Item style={{ display: 'none' }}
        name="id">
        <Input type="hidden"
        />
      </Form.Item>

      <div style={step == 1 ? {} : { display: "none" }}>
        <Form.Item
          name="industryCode"
          label="所属行业"
          rules={[{ required: true, message: '请选择行业' }]}
        >
          <DictSelect dictCode={"INDUSTRY_CODE"} />
        </Form.Item>

        <Form.Item
          name="scope"
          label="使用范围"
          rules={[{ required: true, message: '请选择范围' }]}
        >
          <DictSelect dictCode={"SCOPE"} />
        </Form.Item>

        <Form.Item
          name="name"
          label="名称"
        >
          <Input
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="picture"
          label="图片"
          rules={[{ required: true, message: '请选择图片' }]}
        >
          <ImageUpload ></ImageUpload>
        </Form.Item>
        <Form.Item
          name="remark"
          label="简介"
        >
          <TextArea
            style={{ width: '100%', height: 200 }}
          />
        </Form.Item>

      </div>

      <div style={step == 2 ? {} : { display: "none" }}>
        <Form.Item
          name="images"
          label="实例图片"

          rules={[{ required: true, message: '请选择图片' }]}
        >
          <ImageUpload maxCount={10}></ImageUpload>
        </Form.Item>
        <Form.Item
          name="content"
          label="详细介绍"
        >
          <RichTextEditor />
        </Form.Item>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Space>
          {step == 2 && <Button onClick={() => { setStep(1) }}>上一步</Button>}
          {step == 1 && <Button onClick={() => { setStep(2) }}>下一步</Button>}
        </Space>
      </div>
    </Form>
  </Drawer >)
}

export default Page;