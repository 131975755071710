import React, { useEffect, useState } from 'react'
import { Table, DatePicker, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb, Tree } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";
import { addPage } from 'component/DynamicRotes.js';
import TreeTable from 'component/TreeTable';
import TypeFormDrawer from './type-form.js';

const Page = () => {
    const [pageStatus, setPageStatus] = useState({
        menuId: null,
        drawerId: null
    })

    const metaCode = "paifuWorkType";

    const [param, setParam] = useState({ searchName: "", showRoot: false, metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const [typeFormParam, setTypeFormParam] = useState({
        visible: false,
        id: ""
    });

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam({ ...param })
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setTypeFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <TreeTable
                url={module.app + "/meta/v1.0/data/tree"}
                keyCode="code"
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setTypeFormParam({
                                        id: record.datas.id,
                                        visible: true
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/data/count-by-attr', {
                                            metaCode: metaCode,
                                            attrCode: "parentCode",
                                            attrValue: record.code
                                        }).then(res => {
                                            if (res.data > 0) {
                                                message.error("存在子节点，不允许删除");
                                            } else {
                                                post(module.app + '/meta/v1.0/data/delete', {
                                                    metaCode: metaCode,
                                                    id: record.datas.id
                                                }).then(res => {
                                                    setParam({ ...param });
                                                    message.info("删除成功");
                                                })
                                            }
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <TypeFormDrawer param={typeFormParam} setParam={setTypeFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/worker/type/manager", Page);
export default Page;