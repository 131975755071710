import React, { useEffect, useState, useContext } from 'react';
import { post } from 'util/http';
import module from 'util/module';
import { LoginContext } from 'util/context';

import { addRoute, DynamicPages, setBreadcrumbs } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Image, Avatar, Space, Dropdown, Button } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';


const { Header, Content, Sider } = Layout;


const ALL_MENU_MAP = {};

function Page() {


    const navigate = useNavigate();
    const location = useLocation();

    const menu = (
        <Menu
            onClick={(item) => {
                if (item.key == 'logout') {
                    post(module.auth + '/auth/v1.0/logout', {})
                        .then(res => {
                            window.location.href = "/login";
                        })
                }
            }}
            items={[
                {
                    label: '退出登录',
                    key: 'logout',
                }
            ]}
        />
    );

    const loginUser = useContext(LoginContext);


    return (
        <Header className="xcloud-header">
            <div className="logo" >
                <img src={require('../asserts/images/logo.png')} />
                派服管理系统
            </div>
            <Menu theme="light" mode="horizontal"
                defaultSelectedKeys={[location.pathname]}
                items={[{
                    key: "/",
                    label: "首页",
                }, {
                    key: "/project/approvaling",
                    label: "待审核工程",
                }, {
                    key: "/worker/approvaling",
                    label: "待审核工人",
                }]}
                onClick={(e) => {
                    navigate(e.key);
                }}
                style={{ float: 'left', width: 500 }} />

            <div style={{ float: 'right' }}>
                {/* <Button type="text" style={{ marginRight: 20 }}>控制台</Button> */}
                {loginUser.code && <Dropdown overlay={menu} >
                    <Space style={{ cursor: "pointer" }}>
                        <Avatar src={require('../asserts/images/logo.png')} />
                        <span>{loginUser.name}</span>
                        <DownOutlined />
                    </Space>
                </Dropdown>
                }
                {!loginUser.code &&
                    <Button type="text">登录</Button>
                }
            </div>
        </Header>
    );
}
export default Page;
