import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Tabs,
    Select,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import SearchTree from 'component/SearchTree';
import DictSelect from 'component/DictSelect';
import EditTable from 'component/EditTable';
import { getMetaLabel } from 'util/meta';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


const Page = ({
    param,
    setParam,
}) => {

    const [metaAttrPermissionParam, setMetaAttrPermissionParam] = useState({ metaAttrId: '' });
    const [permissionTargetType, setPermissionTargetType] = useState('account');
    const [viewType, setViewType] = useState();

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();


    return (<Drawer title="字段管理"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false });
            setMetaAttrPermissionParam({ ...metaAttrPermissionParam, metaAttrId: '' })
        }}
        visible={param.visible}
    >

        <Modal title="配置字段"
            width={980}
            visible={param.modalVisible}
            onOk={() => {
                pageForm.validateFields().then((formParam) => {
                    formParam.metaCode = param.metaCode;
                    const url = formParam.id ? module.app + '/meta/v1.0/attr/update' : module.app + '/meta/v1.0/attr/create';
                    post(url, formParam).then(res => {
                        message.info("创建成功");
                        setParam({ ...param, modalVisible: false });
                    })
                })
            }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>
            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    attrs: []
                }}
            >
                <Form.Item style={{ display: 'none' }}
                    name="id">
                    <Input type="hidden" />
                </Form.Item>
                <Row gutter={16}>

                    <Col span={12}>
                        <Form.Item
                            name="attrCode"
                            label="属性代码"
                            rules={[{ required: true, message: '请输入属性代码' }]}
                        >
                            <Input disabled={pageForm.getFieldValue('id') != null} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="columnCode"
                            label="数据库字段"
                            rules={[{ required: true, message: '请输入数据库字段' }]}
                        >
                            <Input disabled={pageForm.getFieldValue('id') != null} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item
                            name="attrName"
                            label="属性名称"
                            rules={[{ required: true, message: '请输入属性名称' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="columnType"
                            label="字段类型"
                            rules={[{ required: true, message: '请选择字段类型' }]}
                        >
                            <DictSelect dictCode="DB_COLUMN_TYPE">
                            </DictSelect >
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="columnLength"
                            label="字段长度"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="isRequired" label="是否必填" >
                            <Select >
                                <Option value={1}>是</Option>
                                <Option value={2}>否</Option >
                            </Select >
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="isUnique" label="是否唯一" >
                            <Select >
                                <Option value={1}>是</Option>
                                <Option value={2}>否</Option >
                            </Select >
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="isSystem" label="系统字段">
                            <Select >
                                <Option value={1}>是</Option>
                                <Option value={2}>否</Option >
                            </Select >
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="defaultVal" label="默认值" >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="showOrder" label="显示顺序">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="viewAuthScope" label="查看权限" >
                            <DictSelect dictCode="META_AUTHORITY">
                            </DictSelect >
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="editAuthScope" label="编辑权限">
                            <DictSelect dictCode="META_AUTHORITY">
                            </DictSelect >
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="viewType" label="显示类型"
                            rules={[{ required: true, message: '请选择显示类型' }]} >
                            <DictSelect dictCode="META_ATTR_VIEW_TYPE" onChange={setViewType}>
                            </DictSelect >
                        </Form.Item>
                    </Col>

                    {
                        (viewType == 'SearchTree') &&
                        <>
                            <Col span={24}>
                                <Form.Item name="viewConfig" label="显示配置" >
                                    <TextArea style={{ height: 150 }} defaultValue={JSON.stringify({
                                        "url": "",
                                        "param": {
                                            "showRoot": false
                                        },
                                        "valueCode": "code"
                                    }
                                    )}></TextArea>
                                </Form.Item>
                            </Col>
                        </>
                    }
                    {
                        viewType == 'DictSelect' &&
                        <>
                            <Col span={18}>
                                <Form.Item name="dictCode" label="选择字典" >
                                    <SearchSelect url={module.app + "/dict/v1.0/search"} showSearch={true} labelCode="name" valueCode="code" onChange={(v) => {
                                        pageForm.setFieldsValue({
                                            viewConfig: JSON.stringify({
                                                dictCode: v
                                            })
                                        })
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button>添加字典</Button>
                            </Col>
                            <Col span={24} style={{ display: 'none' }}>
                                <Form.Item name="viewConfig" label="显示配置" >
                                    <TextArea style={{ height: 150 }} defaultValue={JSON.stringify({
                                        "url": ""
                                    }
                                    )}></TextArea>
                                </Form.Item>
                            </Col>
                        </>
                    }

                </Row>


            </Form>
        </Modal>

        {
            metaAttrPermissionParam.metaAttrId == '' &&
            <div>

                <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                    <Form.Item
                        name="searchName"
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>
                    <Space >
                        <Button onClick={() => {
                            var data = searchForm.getFieldsValue();
                            setParam({ ...param, ...data });
                        }}>搜索</Button>
                        <Button type="primary" onClick={() => {
                            pageForm.resetFields();
                            setParam({ ...param, modalVisible: true })
                        }}>添加字段</Button>
                    </Space>
                </Form>

                <SearchTable url={module.app + "/meta/v1.0/attr/search"}
                    param={param}
                    columns={[
                        {
                            title: '字段名称',
                            dataIndex: 'attrName',
                            key: 'attrName',
                        },
                        {
                            title: '字段代码',
                            dataIndex: 'attrCode',
                            key: 'attrCode',
                        },
                        {
                            title: '排序',
                            dataIndex: 'showOrder',
                            key: 'showOrder',
                        }, {
                            title: '操作',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, record) => (
                                <Space size="middle">

                                    <a onClick={() => {
                                        post(module.app + '/meta/v1.0/attr/get',
                                            { id: record.id })
                                            .then(res => {
                                                pageForm.setFieldsValue(res.data);
                                                setParam({ ...param, modalVisible: true })
                                            })
                                    }}>修改</a>
                                    <a onClick={() => {
                                        setMetaAttrPermissionParam({ ...metaAttrPermissionParam, metaAttrId: record.id })
                                    }}>权限</a>

                                    <Popconfirm
                                        title="你确认删除此数据么?"
                                        onConfirm={() => {
                                            post(module.app + '/meta/v1.0/attr/delete',
                                                { id: record.id })
                                                .then(res => {
                                                    setParam({ ...param });
                                                    message.info("删除成功");
                                                })
                                        }}
                                        okText="确认"
                                        cancelText="取消">
                                        <a href="#">删除</a>
                                    </Popconfirm>



                                </Space>
                            )
                        }
                    ]} />
            </div>

        }

        {
            metaAttrPermissionParam.metaAttrId != '' &&
            <div>
                <Space>
                    <Button onClick={() => {
                        setMetaAttrPermissionParam({ ...metaAttrPermissionParam, metaAttrId: '' })
                    }}>返回</Button>
                    <Button size='middle' type="primary" onClick={() => {
                        setMetaAttrPermissionParam({ ...metaAttrPermissionParam, modalVisible: true });
                        pageForm.resetFields()
                    }}>新增</Button>
                </Space>

                <SearchTable url={module.app + "/meta/v1.0/attr/permission/search"}
                    param={metaAttrPermissionParam}
                    columns={[
                        {
                            title: '权限类型',
                            dataIndex: 'role',
                            key: 'role',
                            render: (_, record) => {
                                return getMetaLabel('META_PERMISSION_ROLE', _);
                            }
                        },
                        {
                            title: '授权类型',
                            dataIndex: 'targetType',
                            key: 'targetType',
                            render: (_, record) => {
                                return getMetaLabel('PERMISSION_TARGET_TYPE', _);
                            }
                        },
                        {
                            title: '授权对象',
                            dataIndex: 'targetName',
                            key: 'targetName',
                        },
                        {
                            title: '操作',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, record) => (
                                <Space size="middle">
                                    <Popconfirm
                                        title="你确认删除此数据么?"
                                        onConfirm={() => {
                                            post(module.app + '/meta/v1.0/attr/permission/delete', {
                                                metaAttrId: metaAttrPermissionParam.metaAttrId,
                                                role: record.role,
                                                targetType: record.targetType,
                                                targetCode: record.targetCode
                                            }).then(res => {
                                                setMetaAttrPermissionParam({ ...metaAttrPermissionParam })
                                                message.info("删除成功");
                                            })
                                        }}
                                        okText="确认"
                                        cancelText="取消">
                                        <a href="#">删除</a>
                                    </Popconfirm>



                                </Space>
                            )
                        }
                    ]} />

                <Modal title="新增权限"
                    visible={metaAttrPermissionParam.modalVisible}
                    onOk={() => {
                        pageForm.validateFields().then((formParam) => {
                            formParam.metaAttrId = metaAttrPermissionParam.metaAttrId;
                            formParam.targetCode = formParam.target.value;
                            formParam.targetName = formParam.target.label;
                            const url = module.app + '/meta/v1.0/attr/permission/create';
                            post(url, formParam).then(res => {
                                message.info("创建成功");
                                setMetaAttrPermissionParam({ ...metaAttrPermissionParam, modalVisible: false })
                            })
                        })
                    }} onCancel={() => {
                        setMetaAttrPermissionParam({ ...metaAttrPermissionParam, modalVisible: false })
                    }}>

                    <Form form={pageForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 80 }
                        }}
                        initialValues={{
                            attrs: []
                        }}
                    >
                        <Form.Item
                            label="权限类型"
                            name="role">
                            <DictSelect dictCode={"META_PERMISSION_ROLE"} ></DictSelect>
                        </Form.Item>
                        <Form.Item
                            label="授权类型"
                            name="targetType">
                            <DictSelect dictCode={"PERMISSION_TARGET_TYPE"} onChange={(v) => {
                                pageForm.setFieldsValue({ target: null })
                                setPermissionTargetType(v);
                            }}></DictSelect>
                        </Form.Item>
                        {
                            permissionTargetType == 'account' &&
                            <Form.Item
                                label="授权对象"
                                name="target">
                                <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                            </Form.Item>
                        }
                        {
                            permissionTargetType == 'role' &&
                            <Form.Item
                                label="授权对象"
                                name="target">
                                <SearchSelect url={module.ac + "/role/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                            </Form.Item>
                        }
                        {
                            permissionTargetType == 'group' &&
                            <Form.Item
                                label="授权对象"
                                name="target">
                                <SearchSelect url={module.ac + "/group/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                            </Form.Item>
                        }
                        {
                            permissionTargetType == 'org' &&
                            <Form.Item
                                label="授权对象"
                                name="target">
                                <SearchTree url={module.ac + "/org/v1.0/tree"} param={{ showRoot: false }} valueCode="code" labelInValue={true} />
                            </Form.Item>
                        }
                    </Form>
                </Modal>
            </div>
        }


    </Drawer>)
}

export default Page;