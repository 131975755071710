import React from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Space, Button, Select } from 'antd';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';

import locale from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
