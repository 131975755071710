import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';
import PointsDrawer from './points'

import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "crmMember";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const [pointsParam, setPointsParam] = useState({
        metaCode: "crmMemberPoints",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];

        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>

                </Space>

            </Form>

            <SearchTable url={module.app + "/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    },
                    {
                        title: '积分',
                        dataIndex: 'points',
                        key: 'points',
                        render: (_, record) => {
                            return <a onClick={() => {
                                setPointsParam({
                                    ...pointsParam,
                                    visible: true,
                                    memberCode: record.code,
                                    params: [{
                                        attrCode: 'memberCode',
                                        operator: "=",
                                        value: record.code
                                    }]
                                })
                            }}>{_}</a>
                        }
                    }
                    // , {
                    //     title: '操作',
                    //     dataIndex: 'name',
                    //     key: 'name',
                    //     render: (_, record) => (
                    //         <Space size="middle">
                    //             <a onClick={() => {
                    //                 setFormParam({
                    //                     id: record.id,
                    //                     visible: true
                    //                 })
                    //             }}>修改</a>
                    //             <Popconfirm
                    //                 title="你确认删除此数据么?"
                    //                 onConfirm={() => {
                    //                     post(module.app + '/meta/v1.0/data/delete', {
                    //                         metaCode: metaCode,
                    //                         id: record.id,
                    //                     }).then(res => {
                    //                         setParam({ ...param });
                    //                         message.info("删除成功");
                    //                     })
                    //                 }}
                    //                 okText="确认"
                    //                 cancelText="取消" >
                    //                 <a href="#">删除</a>
                    //             </Popconfirm>

                    //         </Space>
                    //     )
                    // }
                ]} />
            < FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
            <PointsDrawer setParam={setPointsParam} param={pointsParam}
                reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/crm/member/manager", Page);

export default Page;