import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;
const { TextArea } = Input;

const PageForm = ({
    applicationCode,
    drawerId,
    setParam,
    setPageStatus
}) => {

    const [copyForm] = Form.useForm();

    useEffect(() => {
        if (applicationCode != null && drawerId == 'copyForm') {
            copyForm.setFieldsValue({
                sourceApplicationCode: applicationCode
            });
        }
    }, [applicationCode, drawerId])

    return (<Drawer title="复制应用"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => { setPageStatus({}) }}
        visible={drawerId == 'copyForm'}
        extra={
            <Space>
                <Button onClick={() => { setPageStatus({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = copyForm.getFieldsValue();
                    const url = module.cloud + '/application/v1.0/copy';
                    post(url, param)
                        .then(res => {
                            setParam({});
                            setPageStatus({})
                            message.info("复制成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={copyForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="sourceApplicationCode">
                        <Input type="hidden"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="appId"
                        label="appId"
                        rules={[{ required: true, message: '请输入appId' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入appId"
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"

                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="domain"
                        label="域名"
                    >
                        <Input
                            style={{ width: '100%' }}

                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Drawer>)
}

export default PageForm;