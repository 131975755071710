import React, { useCallback, useEffect, useState, Component } from 'react'
import { Input, DatePicker } from 'antd';
import SearchTree from './SearchTree';
import ImageUpload from './ImageUpload';
import DictSelect from './DictSelect';
import SearchSelect from './SearchSelect';
import RichTextEditor from './RichTextEditor';
import CodeMirrorBox from './CodeMirrorBox.js';
import regionData from 'util/region';
import moment from 'moment';

const { TextArea } = Input;

const NumberField = ({
    ...restProps
}) => {
    return <Input type="number" {...restProps} />
}

const RegionTree = ({
    ...restProps
}) => {
    return <SearchTree initData={regionData} {...restProps} />
}


const DateField = ({
    format = "yyyy-MM-DD",
    value,
    onChange,
    ...restProps
}) => {
    if (typeof value == 'string') {
        value = moment(value);
    }
    return <DatePicker format={format} value={value} onChange={(value) => {
        if (value != null) {
            onChange(value.format(format));
        } else {
            onChange(value)
        }
    }} {...restProps} />
}

const MetaFormItemCompents = {
    Input: Input,
    DateField: DateField,
    NumberField: NumberField,
    ImageUpload: ImageUpload,
    TextArea: TextArea,
    SearchSelect: SearchSelect,
    SearchTree: SearchTree,
    DictSelect: DictSelect,
    RegionTree: RegionTree,
    RichTextEditor: RichTextEditor,
    CodeMirrorBox: CodeMirrorBox
}

const MetaFormItem = ({
    metaAttr,
    value,
    onChange
}) => {
    if (metaAttr.viewType == null || metaAttr.viewType == '') {
        return (<Input value={value} onChange={onChange} ></Input >)
    } else {
        var config = {};
        if (metaAttr.viewConfig != null && metaAttr.viewConfig != '') {
            config = JSON.parse(metaAttr.viewConfig);
        }
        const FormItem = MetaFormItemCompents[metaAttr.viewType];
        if (FormItem != null) {
            return (<FormItem value={value} onChange={onChange} {...config} />)
        } else {
            console.error("FormItem is null {}", metaAttr)
        }
    }
}
export { MetaFormItem as default, DateField };