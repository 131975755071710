import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, Select, message } from 'antd';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import CodeMirrorBox from 'component/CodeMirrorBox';
const { Option } = Select;
const { TextArea } = Input;
const ConfigForm = ({
    configId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [config, setDict] = useState({
        items: []
    });

    const [configForm] = Form.useForm();


    useEffect(() => {
        if ('configForm' == drawerId) {
            configForm.resetFields();
        }
        if (configId != null && drawerId == 'configForm') {
            post(module.app + '/config/v1.0/get', { id: configId })
                .then(res => {
                    setDict(res.data);
                    configForm.setFieldsValue({
                        ...res.data
                    });
                })
        }
    }, [configId, drawerId])

    return (<Drawer title="创建配置项"
        placement="right"
        width={1224}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'configForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = configForm.getFieldsValue();
                    const url = param.id ? module.app + '/config/v1.0/update' : module.app + '/config/v1.0/create';
                    post(url, param)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={configForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                items: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="code"
                        label="配置项"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input />
                    </Form.Item>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="content"
                        label="配置内容"
                    >
                        <CodeMirrorBox
                            defaultValue={configForm.getFieldValue("content")}
                        />
                    </Form.Item>
                </Col>

            </Row>

        </Form>
    </Drawer>)
}

export default ConfigForm;