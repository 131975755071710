import React, { useCallback, useEffect, useState } from 'react'
import { TreeSelect } from 'antd';
import { post } from 'util/http';

const SearchTree = ({
    url,
    value,
    param,
    onChange,
    initData,
    valueCode = "id",
    labelCode = "name",
    allowClear = true,
    ...nextProps
}) => {
    const [treeData, setTreeData] = useState([]);

    const formatData = useCallback((data) => {
        var newData = { title: data[labelCode], value: data[valueCode], children: [], data: data, key: data[valueCode] };
        data.children.map((d) => {
            newData.children.push(formatData(d));
        })
        return newData;
    }, [])


    useEffect(() => {
        if (initData == null) {
            post(url, param || {}).then(res => {
                if (Array.isArray(res.data)) {
                    const newTreeData = res.data.map((item) => {
                        return formatData(item);
                    });
                    setTreeData(newTreeData);
                } else {
                    const newTreeData = formatData(res.data);
                    setTreeData([newTreeData]);
                }
            })
        } else {
            var newData = [];
            initData.map((item) => {
                newData.push(formatData(item));
            })
            setTreeData(newData);
        }

    }, [param])

    return (<TreeSelect onChange={onChange} treeData={treeData} value={value} allowClear={allowClear} {...nextProps} />)
}

export default SearchTree;