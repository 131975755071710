import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Popconfirm, Space, Col, Row, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';

import ApplicationDrawer from './application';

import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import DictSelect from "component/DictSelect";
import { EyeOutlined, EditOutlined, AimOutlined } from '@ant-design/icons';


const Page = () => {
    const [param, setParam] = useState({
        searchName: ""
    });
    const [applicationParam, setApplicationParam] = useState({
        visible: false
    });

    const [pageData, setPageData] = useState({
        size: 1,
        records: []
    });

    const loadData = (param) => {
        post("/api/cloud/public/template/search", param).then(res => {
            console.log(res.data);
            setPageData(res.data);
        })
    }

    useEffect(() => {
        loadData(param);
    }, []);


    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
                <Form.Item name="industryCode" >
                    <DictSelect dictCode={"INDUSTRY_CODE"} placeholder="模块类型" style={{ width: 180 }} />
                </Form.Item>
                <Form.Item name="searchName" >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="关键词"
                    />
                </Form.Item>
                <Space>
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>

                </Space>
            </Form>


            <div className="market">
                <Row gutter={[16, 16]}>
                    {pageData.records.map((item) => (
                        <Col span={6} className="item" key={item.id}>
                            <div className="item-img">
                                <img src={item.picture} />
                                <div className="bg">
                                    <Button><AimOutlined />预览</Button>
                                    <Button style={{ marginTop: 20 }}
                                        onClick={() => {
                                            setApplicationParam({
                                                templateCode: item.code,
                                                visible: true
                                            })
                                        }}
                                    ><EditOutlined /> 创建应用</Button>
                                </div>
                            </div>
                            <div className="title">
                                <div className="count">
                                    <EyeOutlined /> 100
                                </div>
                                <div className='main'>{item.name}</div>
                                <div className='sub'>{item.remark}</div>
                            </div>
                        </Col>
                    ))}


                </Row>
                <Pagination
                    style={{ position: 'absolute', bottom: 10, right: 0 }}
                    pageSize={pageData.size}
                    total={pageData.total}
                    showTotal={(total) => `总共 ${total} 条数据1`}
                    onChange={(current, size) => {
                        loadData({ ...param, current: current, size: size });
                    }}
                />
            </div>

            {/* <SearchTable url={module.cloud + "/public/template/search"}
                param={param}
                columns={[
                    {
                        title: '行业',
                        dataIndex: 'industryCode',
                        key: 'industryCode',
                        render: (_, record) => {
                            return getMetaLabel('INDUSTRY_CODE', _);
                        }

                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: '图片',
                        dataIndex: 'picture',
                        key: 'picture'
                    }, {
                        title: '创建人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setApplicationParam({
                                        templateCode: record.code,
                                        visible: true
                                    })
                                }}>创建应用</a>

                            </Space>
                        )
                    }
                ]} /> */}


            <ApplicationDrawer param={applicationParam} setParam={setApplicationParam} reload={() => { setParam({ ...param }) }} />
        </div>
    );
}

addPage("/public/template", Page);

export default Page;