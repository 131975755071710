import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, } from 'antd';
import EditTable from 'component/EditTable';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from 'component/DictSelect';
import "antd/dist/antd.css";

const Page = ({
    param,
    setParam,
}) => {

    const [accountMetaForm] = Form.useForm();

    const listMetaData = () => {
        post(module.ac + '/account/v1.0/meta/search', {})
            .then(res => {
                accountMetaForm.setFieldsValue({
                    datas: res.data.records
                })
            })
    }

    useEffect(() => {
        if (param.visible) {
            listMetaData();
        }
    }, [param])

    const deleteMetaData = (record) => {
        post(module.ac + '/account/v1.0/meta/delete', { id: record.id })
            .then(res => {
                listMetaData();
            })
    }
    const saveMetaData = (record) => {
        const url = record.id == null ? module.ac + '/account/v1.0/meta/create' : module.ac + '/account/v1.0/meta/update';
        post(url, record)
            .then(res => {
                listMetaData();
            })
    }
    const createColumns = () => {
        const columns = [{
            title: "id",
            dataIndex: "id",
            key: 'id',
            width: 80,
            ellipsis: true,
            editType: <Input disabled={true} />,
            editable: true,
        }, {
            title: "代码",
            dataIndex: "code",
            key: 'code',
            ellipsis: true,
            editable: true,
        }, {
            title: "名称",
            dataIndex: "name",
            key: 'name',
            ellipsis: true,
            editable: true,
        }, {
            title: "类型",
            dataIndex: "type",
            key: 'type',
            ellipsis: true,
            editType: <DictSelect dictCode="ACCOUNT_META_TYPE" />,
            editable: true,
        }];
        return columns;
    }

    return (<Drawer title="账户属性"
        placement="right"
        width={1024}
        getContainer={false}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        extra={
            <Space>
                <Button onClick={() => { }} type="link" >下载模板</Button>
                <Button type="primary" onClick={() => {

                }}>
                    批量导入
                </Button>
            </Space>
        }
        visible={param.visible}
    >
        <Form form={accountMetaForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 60 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="datas"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <EditTable
                            columns={createColumns()}
                            rowDel={(record) => {
                                console.log("re", record)
                                deleteMetaData(record);
                                return false;
                            }}
                            rowSave={(record) => {
                                saveMetaData(record);
                                return false;
                            }}
                        >

                        </EditTable>
                    </Form.Item>
                </Col>

            </Row>

        </Form>
    </Drawer>)
}

export default Page;